/**
 * 获取上周、本周、上月、本月、上季度、本季度的开始日期、结束日期 start
 * 亲测无bug。获取上月开始结束日期考虑了年份的变化
 */
var now = new Date(); //当前日期
var nowDayOfWeek = now.getDay() - 1; //今天本周的第几天
var nowDay = now.getDate(); //当前日
var nowMonth = now.getMonth(); //当前月
var nowYear = now.getYear(); //当前年
nowYear += (nowYear < 2000) ? 1900 : 0; //
var lastMonthDate = new Date(); //上月日期
lastMonthDate.setDate(1);
lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
//var lastYear = lastMonthDate.getYear();
var lastMonth = lastMonthDate.getMonth();

//格式化日期：yyyy-MM-dd
function formatDate(date) {
    var myyear = date.getFullYear();
    var mymonth = date.getMonth() + 1;
    var myweekday = date.getDate();
    if (mymonth < 10) {
        mymonth = "0" + mymonth;
    }
    if (myweekday < 10) {
        myweekday = "0" + myweekday;
    }
    return (myyear + "-" + mymonth + "-" + myweekday);
}

function formatDatetime(date){
	let year = date.getFullYear(),
        month = date.getMonth() + 1,
		day = date.getDate(),
		hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
		minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
        seconds = date.getSeconds();
	month >= 1 && month <= 9 ? (month = "0" + month) : "";
	day >= 0 && day <= 9 ? (day = "0" + day) : "";
	var timer = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + seconds;
	return timer;
}

function formatDateFromMs(ms){
    var date = new Date(ms)
    return formatDatetime(date)
}

//获得某月的天数
function monthDays(myMonth) {
    var monthStartDate = new Date(nowYear, myMonth, 1);
    var monthEndDate = new Date(nowYear, myMonth + 1, 1);
    var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
    return days;
}
//获得本季度的开始月份
function quarterStartMonth() {
    var quarterStartMonth = 0;
    if (nowMonth < 3) {
        quarterStartMonth = 0;
    }
    if (2 < nowMonth && nowMonth < 6) {
        quarterStartMonth = 3;
    }
    if (5 < nowMonth && nowMonth < 9) {
        quarterStartMonth = 6;
    }
    if (nowMonth > 8) {
        quarterStartMonth = 9;
    }
    return quarterStartMonth;
}

function yesterday() {
	var tmp = new Date();
	tmp.setDate(tmp.getDate() - 1)
	return tmp
}

//获得本周的开始日期
function weekStartDate() {
    var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
    return weekStartDate;
}
//获得本周的结束日期
function weekEndDate() {
    var weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
    return weekEndDate;
}
//获得上周的开始日期
function lastWeekStartDate() {
    var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek - 7);
    return weekStartDate;
}
//获得上周的结束日期
function lastWeekEndDate() {
    var weekEndDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek - 1);
    return weekEndDate;
}
//获得本月的开始日期
function monthStartDate() {
    var monthStartDate = new Date(nowYear, nowMonth, 1);
    return monthStartDate;
}
//获得本月的结束日期
function monthEndDate() {
    var monthEndDate = new Date(nowYear, nowMonth, monthDays(nowMonth));
    return monthEndDate;
}
//获得上月开始时间
function lastMonthStartDate() {
    var tmp = null;
    if (lastMonth == 11) {
        tmp = new Date(nowYear - 1, lastMonth, 1);
    } else {
        tmp = new Date(nowYear, lastMonth, 1);
    }
    return tmp;
}
//获得上月结束时间
function lastMonthEndDate() {
    var tmp = null;
    if (lastMonth == 11) {
        tmp = new Date(nowYear - 1, lastMonth, monthDays(lastMonth));
    } else {
        tmp = new Date(nowYear, lastMonth, monthDays(lastMonth));
    }
    return tmp;
}
//获得本季度的开始日期
function quarterStartDate() {
    var quarterStartDate = new Date(nowYear, quarterStartMonth(), 1);
    return quarterStartDate;
}
//获得本季度的结束日期
function quarterEndDate() {
    var quarterEndMonth = quarterStartMonth() + 2;
    var quarterStartDate = new Date(nowYear, quarterEndMonth,
        monthDays(quarterEndMonth));
    return quarterStartDate;
}

function dateTimeMinute() {
	var date = new Date(),
		month = date.getMonth() + 1,
		day = date.getDate(),
		hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
		minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
	month >= 1 && month <= 9 ? (month = "0" + month) : "";
	day >= 0 && day <= 9 ? (day = "0" + day) : "";
	var timer = month + '-' + day + ' ' + hour + ':' + minute;
	return timer;
}


/**
 * 获取时间 end
 */

export default {
    formatDate,
    formatDateFromMs,
    monthDays,
    quarterStartMonth,
    weekStartDate,
    weekEndDate,
    lastWeekStartDate,
    lastWeekEndDate,
    monthStartDate,
    monthEndDate,
    lastMonthStartDate,
    lastMonthEndDate,
    quarterStartDate,
    quarterEndDate,
    yesterday,
    dateTimeMinute
}
