<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>查询事件记录</h5>
				<div class="formgroup-inline">
					<span class="p-float-label mr-3">
						<Dropdown id="eventEnterprise" :options="enterpriseList" v-model="screen.enterprise"
							optionLabel="name" optionValue="id" @change="onEnterpriseChange('search')"
							placeholder="所属企业" :show-clear="true"></Dropdown>
					</span>
					<span class="p-float-label mr-3">
						<Dropdown id="eventField" :options="fieldList" v-model="screen.field" optionLabel="site_name"
							placeholder="所属场地" optionValue="id" @change="onFieldChange('search')" :show-clear="true">
						</Dropdown>
					</span>
					<span class="p-float-label mr-3">
						<Dropdown id="eventRegion" :options="regionList" v-model="screen.department" placeholder="所属区域"
							optionLabel="region_name" optionValue="id" :show-clear="true" @change="onRegionChange">
						</Dropdown>
					</span>

					<span class="p-float-label mr-3">
						<Dropdown id="sensorList" :options="sensorList" v-model="screen.camera" optionLabel="name"
							placeholder="所属摄像头" optionValue="id" :show-clear="true">
						</Dropdown>
					</span>
					<span class="p-float-label mr-3">
						<Calendar id="eventTimeRange" v-model="check.time" selectionMode="range" :manualInput="false"
							showButtonBar="true" :showIcon="true" hideOnDateTimeSelect="true" placeholder="选择时间范围" />
					</span>
				</div>

				<div class="formgroup-inline mt-3">
					<span class="p-float-label mr-3">
						<Dropdown id="eventStatus" :options="statOpt" v-model="check.state" optionLabel="name"
							placeholder="状态" optionValue="id" :show-clear="true"></Dropdown>
					</span>
					<span class="p-float-label mr-3">
						<MultiSelect v-model="screen.eventTypes" :options="presetTypeList" optionLabel="name"
							optionValue="name" placeholder="事件类型" display="chip" />
					</span>

					<Button label="查询" @click="onSearch"></Button>
					<Button label="Word下载" icon="pi pi-file" class="ml-8" @click="onFileDownload(0)"></Button>
					<Button label="Excel下载" icon="pi pi-file-excel" class="ml-3" @click="onFileDownload(1)"></Button>
				</div>
			</div>
		</div>
		<div class="col-12">
			<div class="card">
				<DataView :value="eventRecordList" :layout="layout" :paginator="true" :rows="8" :sortOrder="sortOrder"
					:sortField="sortField" :lazy="true" :totalRecords="totalCount" @page="onPage">
					<template #header>
						<div class="grid grid-nogutter">
							<div class="col-6 text-left">
								<!-- <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label"
									placeholder="按时间排序" @change="onSortChange($event)" v-if="false" /> -->
								<!-- <div class="field-checkbox" style="margin-top: 14px;">
									<Checkbox id="goon" v-model="goOnCheck" :binary="true"
										@change="goOnCheck = !goOnCheck;" />
									<label for="goon">审核后继续</label>
								</div> -->
							</div>
							<div class="col-6 text-right" style="margin-top:0px;">
								<DataViewLayoutOptions v-model="layout" />
							</div>
						</div>
					</template>
					<template #list="slotProps">
						<div class="col-12">
							<div class="flex flex-column md:flex-row align-items-center p-3 w-full"
								:style="{ 'background-color': slotProps.data.selected ? '#F0F8FF' : '#fff' }">
								<img :src="slotProps.data.remind_img" :alt="slotProps.data.position"
									class="my-4 md:my-0 w-9 md:w-10rem shadow-2 mr-5" />
								<div class="flex-1 text-center md:text-left">
									<div class="font-bold text-2xl mb-2">{{ slotProps.data.name }}</div>
									<div class="mb-1">场地：{{ slotProps.data.field_name }}</div>
									<div class="mb-1">区域：{{ slotProps.data.region_name }}</div>
									<div class="mb-1">摄像头：{{ slotProps.data.enterprise_camer_name }}</div>
									<div class="flex align-items-center">
										<i class="pi pi-tag mr-2"></i>
										<span class="font-semibold">{{ slotProps.data.times }}</span>
									</div>
								</div>
								<div class="grid">
									<Button label="详情" class="p-button-info mr-2 mb-2"
										@click="getDetails(slotProps.data, slotProps.index, false)" />
									<!-- <Button label="合规" class="p-button-success mr-2 mb-2" />
									<Button label="违规" class="p-button-danger mr-2 mb-2" /> -->
								</div>

							</div>
						</div>
					</template>

					<template #grid="slotProps">
						<div class="col-12 md:col-3">
							<div class="card m-3 border-1 surface-border"
								:style="{ 'background-color': slotProps.data.selected ? '#F0F8FF' : '#fff' }">
								<div class="text-left">
									<img :src="slotProps.data.remind_img" :alt="slotProps.data.position"
										class="w-full shadow-2 my-0 mx-0" />
									<div class="grid grid-nogutter">
										<div class="col-9 text-left text-sm">
											<div class="font-bold mt-2 mb-1">事件类型：{{ slotProps.data.name }}
											</div>
											<div class="mb-1">场地：{{ slotProps.data.field_name }}</div>
											<div class="mb-1">区域：{{ slotProps.data.region_name }}</div>
											<div class="mb-1">摄像头：{{ slotProps.data.enterprise_camer_name }}</div>
											<div class="flex align-items-center mb-2">
												<i class="pi pi-tag mr-2"></i>
												<span class="font-semibold">{{ slotProps.data.times }}</span>
											</div>
										</div>
										<div
											class="col-3 flex flex-column align-items-end justify-content-between flex-wrap mt-2">
											<Tag severity="success" v-if="slotProps.data.status == 1" value="合规"></Tag>
											<Tag severity="danger" v-else-if="slotProps.data.status == 2" value="违规">
											</Tag>
											<Tag severity="warning" v-else value="未审阅"></Tag>
											<div class="text-right">
												<Button label="详情" class="p-button-info p-button-sm mb-2"
													@click="getDetails(slotProps.data, slotProps.index, false)" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</DataView>
			</div>
		</div>

		<!-- 详情页 -->
		<Dialog header="事件详情" v-model:visible="displayDetail" :style="{ width: '1100px' }" :closeOnEscape="true"
			:modal="true" @hide="onDetailClose">
			<ScrollPanel style="width: 100%; height: 100%">
				<div class="formgrid grid" style="width:1000px">
					<div class="field col">
						<label for="firstname2">
							<Tag class="mr-2" value="首警图" @click="onRuleClick(0)"></Tag>
							<Tag class="mr-2" severity="warning" value="规则" @click="onRuleClick(1)"></Tag>
						</label>
						<div class="flex justify-content-left">
							<img :src="eventDetail.remind_img" :alt="eventDetail.name" width="480" height="270"
								v-if="!showRule" />
							<Card style="width: 100%; height:270px" v-if="showRule">
								<template #title>
									{{ eventDetail.name }}
								</template>
								<template #content>
									{{ eventDetail.camera_rules.detail }}
								</template>
							</Card>
						</div>
					</div>
					<div class="field col">
						<label for="lastname2">
							<Tag class="mr-2" severity="success" value="视频"></Tag>
						</label>
						<div class="flex justify-content-left">
							<div style="width:480px; height: 270px; position: relative;">
								<easy-player id="videoplayer" live="true" show-custom-button="true"
									v-bind:video-url="eventDetail.remind_video" decode-wasm="true">
								</easy-player>
							</div>
						</div>
					</div>
				</div>

				<div class="formgrid grid" style="width:1000px">
					<div class="field col-12 md:col-6 flex-column ">
						<div class="col mb-3 mt-2 grid">
							<div class="col">
								<span class="product-badge status-instock mr-2">企业</span>
								<span class="product-badge-normal">
									{{ eventDetail.enterprise_name }}
								</span>
							</div>
						</div>
						<div class="col mb-3 mt-3 grid">

							<div class="col">
								<span class="product-badge status-instock mr-2">场地</span>
								<span class="product-badge-normal">{{ eventDetail.site_name }}</span>
							</div>
						</div>

						<div class="col mb-3 mt-3 grid">

							<div class="col">
								<span class="product-badge status-instock mr-2">区域</span>
								<span class="product-badge-normal">{{ eventDetail.region_name }}</span>
							</div>
						</div>

						<div class="col mb-3 mt-3 grid">

							<div class="col">
								<span class="product-badge status-instock mr-2">点位</span>
								<span class="product-badge-normal">{{ eventDetail.enterprise_camer_name
								}}</span>
							</div>
						</div>

						<div class="col mb-3 mt-3 grid">
							<div class="col">
								<span class="product-badge status-instock mr-2">摄像头IP</span>
								<span class="product-badge-normal">{{ eventDetail.mac }}</span>
							</div>
						</div>

						<div class="col mb-3 mt-3 grid">
							<div class="col">
								<span class="product-badge status-instock mr-2">拍摄时间</span>
								<span class="product-badge-normal">{{ eventDetail.times }}</span>
							</div>
						</div>

						<div class="col mb-3 mt-3 grid">
							<div class="col">
								<span class="product-badge status-instock mr-2">审核状态</span>
								<Tag severity="success" v-if="eventDetail.status == 1" value="合规"></Tag>
								<Tag severity="danger" v-else-if="eventDetail.status == 2" value="违规">
								</Tag>
								<Tag severity="warning" v-else value="未审阅"></Tag>
							</div>
						</div>
					</div>

					<div class="field col-12 md:col-6 flex-column ">
						<div class="col mt-2 mb-3">
							<span class="product-badge status-instock mr-2">违规事件</span>
							<span class="product-badge-normal">
								<Dropdown v-model="eventDetail.name" :options="presetTypeList" optionLabel="name"
									optionValue="value" placeholder="选择事件类型" v-if="localUserInfo.admin != 0" />
								<span v-else>{{ eventDetail.name }}</span>
							</span>
						</div>

						<div class="col mt-2 mb-3">
							<span class="product-badge status-instock mr-2">风险等级</span>
							<span class="product-badge-normal">
								<Dropdown v-model="eventDetail.level" :options="riskLevel" optionLabel="name"
									optionValue="value" placeholder="选择风险等级" v-if="localUserInfo.admin != 0" />
								<span v-else>{{ getLevelName(eventDetail.level) }}级</span>
							</span>
						</div>

						<div class="col mt-2 flex align-items-start">
							<span class="product-badge status-instock mr-2 mt-1">违规判别</span>

							<div class="flex flex-column mt-1 ml-2">
								<!-- <RadioButton id="rbCompliance" name="city" value="good" v-model="complianceSel" />
									RadioButton id="rbNotCompliance" name="city" value="notgood" v-model="complianceSel" /> -->
								<!-- <Button label="合规"
									:class="[{ 'p-button-success': complianceTemp != 2 }, { 'p-button-secondary': complianceTemp == 1 }, 'p-button-sm']"
									:disabled="complianceTemp == 2" @click="onComplianceClick(1)" />
								<Button label="违规"
									:class="[{ 'p-button-danger': complianceTemp != 1 }, { 'p-button-secondary': complianceTemp == 2 }, 'p-button-sm', 'mt-2']"
									:disabled="complianceTemp == 1" @click="onComplianceClick(0)" /> -->
								<Button label="合规" :class="['p-button-success', 'p-button-sm']"
									:disabled="showDetailCompliance" @click="onComplianceClick(0)" />
								<Button label="违规" :class="['p-button-danger', 'p-button-sm', 'mt-2']"
									:disabled="showDetailCompliance" @click="onComplianceClick(1)" />
							</div>

							<span class="product-badge-normal" v-if="showDetailCompliance">
								<Textarea v-model="complianceText" rows="3" cols="30" :placeholder="complianceHolder" />
							</span>

							<div class="flex flex-column mt-1" v-if="showDetailCompliance">
								<Button label="确定" class="p-button-sm" @click="onEnterCompliance(0)" />
								<Button label="取消" class="p-button-sm p-button-outlined p-button-secondary mt-2"
									@click="onEnterCompliance(1)" />
							</div>
						</div>

						<div class="col mt-2 flex align-items-start" v-if="false">
							<span class="product-badge status-instock mr-2 mt-1">继续审核</span>
							<div class="mt-1 ml-2 flex flex-grow-1 justify-content-between">
								<Button label="上一条" class="p-button-sm p-button-outlined" style="width:190px" />
								<Button label="下一条" class="p-button-sm p-button-outlined" style="width:190px"
									@click="onNextEvent" />
							</div>
						</div>
					</div>

					<div class="formgrid grid m-3 mt-0" style="width:1000px" v-if="localUserInfo.admin != 0">
						<Panel header="审核记录" :toggleable="true">
							<div class="flex flex-column" style="width:930px;">
								<div class="flex py-2" style="border-bottom:1px #f2f2f2 solid"
									v-for="(item, index) in eventDetail.records" @click="onDetailRecordClick(index)"
									:key="item.id">
									<div class="flex-none flex align-items-center justify-content-center bg-blue-500 font-bold text-white border-circle"
										style="width:50px; height:50px">
										<img :src="item.head_img != null && item.head_img != '' ? item.head_img : 'images/avatar/male_user.svg'"
											style="width:50px; height:50px; border-radius: 50%;" />
									</div>
									<div
										class="flex-grow-1 product-badge-normal lowercase flex align-items-center justify-content-center ml-2">
										{{ item.text }}
									</div>
									<div class="flex-none flex product-badge-normal align-items-center justify-content-center ml-2"
										style="width:150px">
										{{ item.cur_type == null ? '最开始' : item.cur_type }}
									</div>
									<div class="flex-none flex product-badge-normal capitalize align-items-center justify-content-center ml-2"
										style="width:70px">
										{{ item.username }}</div>
									<div class="flex-none flex product-badge-normal align-items-center justify-content-center ml-2"
										style="width:110px">
										{{ item.times }}</div>
									<div
										class="flex-none flex align-items-center justify-content-center product-badge-normal">
										<Button label="删除" class="p-button-danger p-button-text mr-1"
											@click="onEventComplianceDel(index, $event)" />
									</div>
								</div>
							</div>
						</Panel>
					</div>
				</div>
			</ScrollPanel>
		</Dialog>
	</div>
	<Dialog v-model:visible="displayDownload" modal header="下载文件中" :style="{ width: '10vw' }">
		<div class="flex flex-wrap justify-content-center ">
			<p>
			文件正在下载中，请不要关闭本窗口，也不要刷新页面
			</p>
			<ProgressSpinner />
		</div>
	</Dialog>
</template>

<script>
import ProductService from "../service/ProductService";
import dateUtil from "../utils/dateUtil";
import axios from 'axios';

export default {
	data() {
		return {
			dataviewValue: null,
			displayDetail: false,
			displayDownload: false,
			detailData: null,
			layout: 'grid',
			sortKey: null,
			sortOrder: null,
			videoUrl: '',
			dates2: null,
			sortField: null,
			sortOptions: [
				{ label: '时间从近到远', value: '!price' },
				{ label: '时间从远到近', value: 'price' },
			],

			// ---------------------------------------------
			screen: {
				enterprise: null,
				field: null,
				department: null,
				eventTypes: [],
				camera: null,
			},
			check: {
				field: '',
				region: '',
				state: null,
				time: ''
			},
			eventRecordList: null,
			eventSelectedIndex: 0,
			eventDetail: {},
			totalCount: 0, // 数据总数
			page_index: 1, // 当前页数
			page_size: 8, // 每页条数

			//   状态
			statOpt: [
				{
					id: 0,
					name: '待审核'
				},
				{
					id: 1,
					name: '合规'
				},
				{
					id: 2,
					name: '违规'
				},
				{
					id: 3,
					name: '全部'
				},
				{
					id: 'ysh',
					name: '已审核'
				}
			],

			enterpriseList: null,
			fieldList: null,
			regionList: null,

			enterTreeIdNameMap: null,
			selectedRiskLevel: null,
			selectedEvent: null,
			presetTypeList: null,
			riskLevel: [
				{ name: 'A级', value: 1 },
				{ name: 'B级', value: 2 },
				{ name: 'C级', value: 3 },
				{ name: 'D级', value: 4 }
			],
			complianceSel: null,
			showDetailCompliance: false,
			complianceText: '',
			complianceHolder: "",
			complianceTemp: 0,
			showRule: false,
			goOnCheck: false,

			sensorList: [],
			localUserInfo: null,
			// ---------------------------------------------


			//测试用
			selectedCity: null,
			cities: [
				{ name: 'New York', code: 'NY' },
				{ name: 'Rome', code: 'RM' },
				{ name: 'London', code: 'LDN' },
				{ name: 'Istanbul', code: 'IST' },
				{ name: 'Paris', code: 'PRS' }
			]
			//测试用

		}
	},
	productService: null,
	created() {
		this.productService = new ProductService();
	},
	mounted() {
		this.enterTreeIdNameMap = new Map();

		this.localUserInfo = JSON.parse(window.localStorage.getItem('userinfo'));
		this.getEnterpriseList();
		this.getPresetTypes();
		this.initRecords();
	},
	methods: {
		async initRecords() {
			var enter_tree = await this.getEnterTree();

			if (enter_tree) {
				this.getEventRecords();
			}
		},

		// 获取企业列表
		getEnterpriseList() {
			this.$http('/api/admin/enterprise/index', {}).then(res => {
				if (res.code == 200) {
					this.enterpriseList = res.data
				}
			})
		},

		// 获取场地列表
		getFieldList(enterprise_id) {
			this.$http('/api/admin/partition/index', { enterprise_id }).then(res => {
				if (res.code == 200) {
					this.fieldList = res.data.data
				}
			})
		},

		// 获取区域列表
		getRegionList(enterprise_partition_id) {
			this.$http('/api/admin/region/cdindex', { enterprise_partition_id }).then(res => {
				if (res.code == 200) {
					this.regionList = res.data
				}
			})
		},

		getSensorList() {
			//this.loading = true
			this.$http('/api/admin/camera/index', {
				enterprise_id: this.screen.enterprise,
				enterprise_partition_id: this.screen.enterprise_partition_id,
				region_id: this.screen.department,
				page_size: 10000
			}).then(res => {
				if (res.code == 200) {
					this.sensorList = res.data.data
				} else {
					this.$toast.add({ severity: 'error', summary: '请求错误', detail: res.msg, life: 3000 });
				}
			})
		},

		buildEnterMap(nodes) {
			for (var i = 0; i < nodes.length; i++) {
				this.enterTreeIdNameMap.set(nodes[i].value, nodes[i].text);

				if (nodes[i].children && nodes[i].children.length != 0) {
					this.buildEnterMap(nodes[i].children)
				}
			}
		},

		// 获取全部企业，以树状列表的形式获取
		getEnterTree() {
			return this.$http("/api/admin/enterprise/tree").then((res) => {
				if (res.code == 200) {
					this.buildEnterMap(res.data, '');
					return true;
				}
				else {
					return false;
				}
			})
		},

		getPresetTypes() {
			this.$http('/api/admin/event/typePreset').then(res => {
				if (res.code == 200) {
					this.presetTypeList = res.data.map((e) => {
						let ee = new Object();

						ee.name = e.name;
						ee.value = e.name;

						return ee;
					});
				}
			})
		},

		getLevelName(level) {
			if (level == 1) {
				return 'A';
			} else if (level == 2) {
				return 'B';
			} else if (level == 3) {
				return 'C';
			} else if (level == 4) {
				return 'D';
			} else {
				return level;
			}
		},

		getEventRecords() {
			this.$http('/api/admin/event/index', {
				//enterprise_id: JSON.parse(window.localStorage.getItem('userinfo')).type == 'ad' ? '' : JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				enterprise_id: this.screen.enterprise,
				enterprise_partition_id: this.screen.field,
				region_id: this.screen.department,
				status: this.check.state,
				time: this.check.time == '' ? '' : new Date(this.check.time[0]).getTime(),
				times: this.check.time == '' ? '' : new Date(this.check.time[1]).getTime() + 86399999,
				enterprise_camer_id: this.screen.camera,
				type_names: this.screen.eventTypes,
				page: this.page_index,
				page_size: this.page_size
			}).then(res => {
				if (res.code == 200) {
					this.eventRecordList = res.data.data.map(e => {
						var ee = new Object();

						ee = e;
						ee.selected = false;

						console.log(this.enterTreeIdNameMap)

						var field_full_id = e.enterprise_id + '-' + e.enterprise_partition_id
						ee.field_name = this.enterTreeIdNameMap.get(field_full_id)

						console.log(field_full_id)
						console.log(ee.field_name)

						if (e.enterprise_partition_region_id) {
							var region_full_id = e.enterprise_id + '-' + e.enterprise_partition_id + '-' + e.enterprise_partition_region_id
							ee.region_name = this.enterTreeIdNameMap.get(region_full_id)
						} else {
							ee.region_name = '[无区域]'
						}

						return ee;
					})

					console.log(this.eventRecordList)

					this.totalCount = res.data.total
					this.page_size = res.data.per_page
				} else {
					this.$message.error(res.msg)
				}
			})
		},

		getDetails(row, index, inner) {
			this.eventDetail = {};

			this.$http('/api/admin/event/desc', {
				id: row.id
			}).then(res => {
				if (res.code == 200) {
					res.data.shr = res.data.users_info.length == 0 ? '' : res.data.users_info[0].user_name
					this.eventDetail = res.data;
					this.eventDetail.currentType = this.eventDetail.name;
				} else {
					this.$toast.add({ severity: 'error', summary: '请求错误', detail: 'Name: ' + event.data.name, life: 3000 });
				}
			})

			this.complianceSelBtnIndex = -1;
			this.showDetailCompliance = false;
			this.complianceText = '';
			this.complianceTemp = 0;
			this.displayDetail = true;
			this.showRule = false;
			this.eventSelectedIndex = index;

			if (!inner) {
				for (let i = 0; i < this.eventRecordList.length; i++) {
					this.eventRecordList[i].selected = false;
				}
				this.eventRecordList[index].selected = true;
			}
		},

		onEnterpriseChange(type) {
			if (type == 'search') {
				this.getFieldList(this.screen.enterprise)
			} else {
				this.getFieldList(this.lodingarr.enterprise)
			}
		},

		onFieldChange(type) {
			if (type == 'search') {
				this.getRegionList(this.screen.field)
			} else {
				this.getRegionList(this.lodingarr.field)
			}
		},

		onRegionChange() {
			this.getSensorList();
		},

		onComplianceClick(type) {
			if (this.showDetailCompliance)
				return;

			if (type == 0) {
				this.complianceHolder = "可输入“合规”意见,也可默认为空";
				this.complianceTemp = 1; //合规是1，违规是2
			} else {
				this.complianceHolder = "可输入“违规”意见,也可默认为空";
				this.complianceTemp = 2;
			}
			this.complianceText = "";
			this.showDetailCompliance = true;

		},

		onGoOnCheck() {
			this.goOnCheck = !this.goOnCheck;
			//console.log(this.goOnCheck)
		},

		onEnterCompliance(type) {
			// console.log(this.selectedEvent)
			// console.log(this.selectedRiskLevel)
			// console.log(this.eventDetail.currentType)

			if (type == 0) {
				// 发送合规/违规意见
				// id, status, remarks, last_type, cur_type, level
				this.$http('/api/admin/event/operation', {
					id: this.eventDetail.id,
					status: this.complianceTemp,
					remarks: this.complianceText,
					last_type: this.eventDetail.currentType,
					cur_type: this.eventDetail.name,
					level: this.eventDetail.level
				}).then(res => {
					if (res.code == 200) {
						//将当前合规/违规操作生成一条记录
						//console.log('ret id: ' + res.data)

						//var usr_info = uni.getStorageSync('userinfo');
						var usr_info = JSON.parse(window.localStorage.getItem('userinfo'));

						var new_record = new Object();
						new_record.id = res.data;
						new_record.head_img = usr_info.head_img
						new_record.username = usr_info.username
						new_record.cur_type = (this.selectedEvent == null ? null : this.selectedEvent.name);
						new_record.text = this.complianceText
						new_record.times = dateUtil.dateTimeMinute();
						new_record.status = this.complianceTemp

						this.eventDetail.records.push(new_record)
						this.eventDetail.status = this.complianceTemp;

						//修改：合规、事件类型两个属性
						if (this.eventDetail.name)
							this.eventRecordList[this.eventSelectedIndex].name = this.eventDetail.name;

						this.eventRecordList[this.eventSelectedIndex].status = new_record.status;

					} else {
						this.$toast.add({ severity: 'error', summary: '请求错误', detail: res.msg, life: 3000 });
					}
				})
			}

			this.showDetailCompliance = false;
			//this.complianceTemp = 0;
		},

		onNextEvent() {
			console.log(this.eventDetail)

			//如果下一条/上一条记录超过了eventRecordList的范围，则请求下一页/上一页的数据
			//如果下一页的数据小于page_size，并且已经触达eventRecordList的length，则提示到底了
			if (this.eventSelectedIndex >= this.eventRecordList.length - 1) {
				//
			}
			else {
				//
			}
		},

		onDetailClose() {
		},

		onSearch() {
			this.getEventRecords();
		},

		downloadEvt(url, fileName = '未知文件') {
			const el = document.createElement('a');
			el.style.display = 'none';
			el.setAttribute('target', '_blank');
			/**
			 * download的属性是HTML5新增的属性
			 * href属性的地址必须是非跨域的地址，如果引用的是第三方的网站或者说是前后端分离的项目(调用后台的接口)，这时download就会不起作用。
			 * 此时，如果是下载浏览器无法解析的文件，例如.exe,.xlsx..那么浏览器会自动下载，但是如果使用浏览器可以解析的文件，比如.txt,.png,.pdf....浏览器就会采取预览模式
			 * 所以，对于.txt,.png,.pdf等的预览功能我们就可以直接不设置download属性(前提是后端响应头的Content-Type: application/octet-stream，如果为application/pdf浏览器则会判断文件为 pdf ，自动执行预览的策略)
			 */
			fileName && el.setAttribute('download', fileName);
			el.href = url;
			console.log(el);
			document.body.appendChild(el);
			el.click();
			document.body.removeChild(el);
		},

		onFileDownload(type){
			let url_str = null

			if(type == 1){
				url_str = 'https://www.heysens.cloud:8443/api/admin/event/exportExcel';
			}
			else {
				url_str = 'https://www.heysens.cloud:8443/api/admin/event/exportWord';
			}

			// url_str = url_str + '?enterprise_id=' + (this.screen.enterprise ? this.screen.enterprise : '')
			// url_str = url_str + '&enterprise_partition_id=' + (this.screen.field ? this.screen.field : '')
			// url_str = url_str + '&region_id=' + (this.screen.department ? this.screen.department : '')
			// url_str = url_str + '&status=2'
			// url_str = url_str + '&time=' + ((this.check.time == '' || !this.check.time[0]) ? '' : new Date(this.check.time[0]).getTime())
			// url_str = url_str + '&times=' + ((this.check.time == '' || !this.check.time[1]) ? '' : new Date(this.check.time[1]).getTime() + 86399999)
			// url_str = url_str + '&type_names=' + this.screen.eventTypes

			let data =  {
							enterprise_id: this.screen.enterprise,
							enterprise_partition_id: this.screen.field,
							region_id: this.screen.department,
							status: this.check.state,
							time: this.check.time == '' ? '' : new Date(this.check.time[0]).getTime(),
							times: this.check.time == '' ? '' : new Date(this.check.time[1]).getTime() + 86399999,
							enterprise_camer_id: this.screen.camera,
							type_names: this.screen.eventTypes
						}
			
			this.displayDownload = true;
						
			axios.post(url_str, data,{
				headers: {
					admintoken: this.localUserInfo.token,
				},
				responseType: 'arraybuffer', // 切记类型 blob
			}).then((res) => {
				if(res.status == 200){
					let file_ext = 'xlsx'
					if(res.headers['content-type'].indexOf('word', 0) != -1){
						file_ext = 'docx'
					}

					let blob = new Blob([res.data]);
					let url = window.URL.createObjectURL(blob); // 创建 url 并指向 blob
					let a = document.createElement('a');
					a.href = url;
					a.download = 'Report.' + file_ext;
					a.click();
					window.URL.revokeObjectURL(url); // 释放该 url
				}else{
					this.$error('参数错误', this);
				}

				this.displayDownload = false;
			}).catch((err) => {
				this.$error('发生网络错误', this);
				console.log(err);
				this.displayDownload = false;
			});
		},

		onDetailRecordClick(index) {
			console.log(index)
		},

		onRuleClick(type) {
			if (this.showRule && type == 0)
				this.showRule = false;
			else if (!this.showRule && type == 1)
				this.showRule = true;
		},

		onPage(event) {
			//this.cars = //load the data between (event.first) and (event.first + event.rows) from a remote datasource
			//console.log(event);
			this.page_index = event.page + 1;
			this.getEventRecords();
		},

		openDetail(d_data) {
			//this.videoUrl = "https://device-static.oss-cn-beijing.aliyuncs.com/device/2022-06-05/c2b121a4-9b0d-4117-b717-1d311f1ec688_6_172.168.20.25-m3u8-1654402615725/c2b121a4-9b0d-4117-b717-1d311f1ec688_6_172.168.20.25_7_1654402615725.m3u8";
			this.videoUrl = "https://device-static.oss-cn-beijing.aliyuncs.com/device/2022-06-06/c2b121a4-9b0d-4117-b717-1d311f1ec688_4_172.168.20.7-m3u8-1654497011527/c2b121a4-9b0d-4117-b717-1d311f1ec688_4_172.168.20.7_1_1654497011527.m3u8";
			this.displayDetail = true;
			this.detailData = d_data;
		},
		onSortChange(event) {
			const value = event.value.value;
			const sortValue = event.value;

			if (value.indexOf('!') === 0) {
				this.sortOrder = -1;
				this.sortField = value.substring(1, value.length);
				this.sortKey = sortValue;
			}
			else {
				this.sortOrder = 1;
				this.sortField = value;
				this.sortKey = sortValue;
			}
		},
		confirmOp(e_id, e_type, event) {
			console.log('e_id' + e_id);
			console.log('e_type' + e_type);

			var msg = "确定合规吗?"
			if (e_type == 2)
				msg = "确定违规吗?"

			this.$confirm.require({
				target: event.currentTarget,
				message: msg,
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.$http('/api/admin/event/operation', {
						id: e_id,
						status: e_type,
						remarks: ''
					}).then(res => {
						if (res.code == 200) {
							this.$toast.add({ severity: 'success', summary: '确认', detail: '确认成功', life: 1000 });
							this.getEventRecords();
						}
						else {
							this.$toast.add({ severity: 'error', summary: '操作失败', detail: res.msg, life: 3000 });
						}
					})
				},
				reject: () => {
				}
			});
		},
		onEventComplianceDel(idx, event) {
			this.$confirm.require({
				target: event.currentTarget,
				message: '确定删除该条审核记录吗',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					let _id = this.eventDetail.records[idx].record_id;

					this.$http('/api/admin/event/delrecord', {
						id: _id
					}).then(res => {
						if (res.code == 200) {
							//将当前合规/违规操作生成一条记录
							this.eventDetail.records.splice(idx, 1)
						} else {
							this.$toast.add({ severity: 'error', summary: '操作失败', detail: res.msg, life: 3000 });
						}
					})
				},
				reject: () => {
				}
			});
		},
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';

.goOnCheckActive {
	font-size: 20px;
	font-weight: bold;
	background-color: aqua;
}

.goOnCheckDeactive {
	font-size: 20px;
	font-weight: bold;
	background-color: #000000;
}


.custom .p-scrollpanel-wrapper {
	border-right: 9px solid #f4f4f4;
}

.custom .p-scrollpanel-bar {
	background-color: #1976d2;
	opacity: 1;
	transition: background-color .3s;
}

.custom .p-scrollpanel-bar:hover {
	background-color: #135ba1;
}
</style>
